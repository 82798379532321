<template>
  <div>

    <!-- Alert: No item found -->
    <!--    <b-alert-->
    <!--      variant="danger"-->
    <!--      :show="itemData === undefined"-->
    <!--    >-->
    <!--      <h4 class="alert-heading">-->
    <!--        Error fetching customer data-->
    <!--      </h4>-->
    <!--      <div class="alert-body">-->
    <!--        No customer found with this customer id. Check-->
    <!--        <b-link-->
    <!--          class="alert-link"-->
    <!--          :to="{ name: 'customers.list'}"-->
    <!--        >-->
    <!--          Customers List-->
    <!--        </b-link>-->
    <!--        for other customers.-->
    <!--      </div>-->
    <!--    </b-alert>-->

    <customer-details-loader
      v-if="isLoading"
    />
    <template v-else-if="itemData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
        >
          <customer-info-card
            :item-data="itemData"
            @edit="isSidebarActive=true"
          />
        </b-col>
      </b-row>

      <!--      <b-row>-->
      <!--        <b-col-->
      <!--          cols="12"-->
      <!--          lg="6"-->
      <!--        >-->
      <!--          <customer-timeline-card />-->
      <!--        </b-col>-->
      <!--        <b-col-->
      <!--          cols="12"-->
      <!--          lg="6"-->
      <!--        >-->
      <!--          <customer-permissions-card />-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <!--      <invoice-list />-->
      <model-list-table
        :store-module-name="storeModuleName"
        :table-columns="tableColumns"
        :filters-list="filtersList"
        :query-params="getQueryParams"
        :add-action="true"
        :view-action="true"
        :edit-action="true"
        :delete-action="true"
        :extra-actions="[]"
        :is-sortable="false"
      >
        <template #filters>
          <i-input
            v-model="statusFilter"
            type="select"
            :placeholder="$t('inputs.status')"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="orderStatusOptions"
            :reduce="val => val.value"
            class="invoice-filter-select mb-0"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </i-input>
        </template>
      </model-list-table>

      <sidebar-add-new-customer
        :is-sidebar-active.sync="isSidebarActive"
        :item-id="itemData.id"
        :value="itemData"
        @refetch-data="fetchCustomer"
      />
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted, computed } from 'vue'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import customerStoreModule from '@/views/models/customers/customerStoreModule'
import { formatDatetime, priceText } from '@core/utils/filter'
import orderStoreModule from '@/views/models/orders/orderStoreModule'
import ModelListTable from '@/views/models/common/ModelListTable.vue'
import CustomerDetailsLoader from '@/views/loaders/CustomerDetailsLoader.vue'
import { orderStatusOptions } from '@core/utils/constants'
import SidebarAddNewCustomer from '@/views/models/customers/add/SidebarAddNewCustomer.vue'
import CustomerInfoCard from './CustomerInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,

    CustomerDetailsLoader,
    // Local Components
    CustomerInfoCard,
    SidebarAddNewCustomer,
    // CustomerTimelineCard,
    // CustomerPermissionsCard,

    // InvoiceList,
    ModelListTable,
  },
  setup() {
    const itemData = ref({
      id: null,
    })
    const isLoading = ref(false)

    const STORE_MODULE_NAME = 'customers'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const fetchCustomer = () => {
      isLoading.value = true
      store.dispatch('customers/fetchOne', router.currentRoute.params.id)
        .then(response => {
          itemData.value = response
        })
        .catch(error => {
          if (error.response.status === 404) {
            itemData.value = null
            router.push({ name: 'customers.list' })
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    fetchCustomer()

    const ORDERS_STORE_MODULE_NAME = 'orders'

    // Register module
    if (!store.hasModule(ORDERS_STORE_MODULE_NAME)) store.registerModule(ORDERS_STORE_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDERS_STORE_MODULE_NAME)) store.unregisterModule(ORDERS_STORE_MODULE_NAME)
    })

    const tableColumns = [
      {
        key: 'checkbox',
        label: '',
        sortable: false,
      },
      { key: 'id', sortable: false, label: 'ID' },
      {
        key: 'total', sortable: true, label: 'Order Total', formatter: priceText,
      },
      { key: 'order_status', sortable: true, label: 'Status' },
      {
        key: 'paid_at',
        label: 'Payment Date',
        formatter: formatDatetime,
        sortable: true,
      },
      {
        key: 'created_at',
        label: 'Creation Date',
        formatter: formatDatetime,
        sortable: true,
      },
      {
        key: 'updated_at',
        label: 'Last Update',
        formatter: formatDatetime,
        sortable: true,
      },
      { key: 'actions' },
    ]

    const statusFilter = ref(null)
    const filtersList = [/* customerFilter, */statusFilter]

    const isSidebarActive = ref(false)

    const getQueryParams = () => ({
      customer_id: router.currentRoute.params.id,
      status: statusFilter.value,
    })
    return {
      isLoading,
      itemData,
      fetchCustomer,

      // Table props
      tableColumns,
      storeModuleName: ORDERS_STORE_MODULE_NAME,
      filtersList,
      statusFilter,
      getQueryParams,

      orderStatusOptions,

      isSidebarActive,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
